import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import notfound from "@/views/not_found.vue";

const home = () => import("@/views/Home.vue");
const suppliers = () => import("@/views/suppliers");
const leaders = () => import("@/views/leaders");
const routes = [
  {
    path: "/suppliers",
    name: "suppliers",
    component: suppliers,
    meta: {
      title: "suppliers",
      requiresAuth: false,
    },
  },
  {
    path: "/leaders",
    name: "leaders",
    component: leaders,
    meta: {
      title: "leaders",
      requiresAuth: false,
    },
  },
  {
    path: "/:id",
    name: "home",
    component: home,
    meta: {
      title: "home",
      requiresAuth: false,
    },
  },

  {
    path: "*",
    name: "notfound",
    component: notfound,
    meta: {
      title: "notfound",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

// router.beforeEach(async (to, from, next) => {
//   document.title = to.meta.title;

//   if (to.name != "notfound") {
//     if (!to.matched.some((record) => record.meta.requiresAuth)) {
//       if (!store.getters.loggedin) {
//         next();
//       } else {
//         if (store.state.checkly_user.super_admin) {
//           next("/companies");
//         } else {
//           next("/products");
//         }
//       }
//     } else if (to.matched.some((record) => record.meta.requiresAuth)) {
//       if (!store.getters.loggedin) {
//         next({
//           name: "login",
//         });
//       } else {
//         if (store.state.checkly_user.super_admin) {
//           if (to.name != "companies") {
//             next("*");
//           } else {
//             next();
//           }
//         } else {
//           if (to.name == "companies") {
//             next("*");
//           } else {
//             next();
//           }
//         }
//       }
//     }
//   } else {
//     next();
//   }
// });
