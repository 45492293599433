<template>
  <v-container class="text-center">
    <img src="@/assets/error.png" />
  </v-container>
</template>

<script>
export default {
  name: "notfound",

};
</script>

<style lang="scss" scoped>
img {
  max-width: 500px;
  display: block;
  margin: 40px auto;
}
</style>
